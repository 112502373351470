import React from "react"

// ヒーローのタイトル
// 表示位置
// - @tablet => positionStyleによる
// - @mobile => center
const Title = ({ positionStyle, children }) => (
  <h1 className={`title is-size-1-tablet has-text-white ${positionStyle} has-text-centered-mobile`} style={{ letterSpacing: ".035em", margin: "0 0 2rem" }}>{children}</h1>
)

export default Title