import React from "react"
import Header from "src/components/section/header"
import Title from "src/components/section/title"
import Copy from "src/components/section/salescopy"
import Image from "src/components/section/image"
import Spacer from "src/components/spacer"
import Wrap from "src/components/wrap"
import image from "src/images/image_index_hero@2x-min.png"

const Caption = () => (
  <Header>
    <Title positionStyle="has-text-centered">活用例</Title>
    <Copy positionStyle="has-text-centered">
      <Wrap><span>シーンに応じたnatadeCOCOの活用例を</span><span>ご紹介します。</span></Wrap>
    </Copy>
    <Spacer isHalf />
    <Image src={image} alt="活用例を紹介する人" />
  </Header>
)

export default Caption