import React from "react"
import { Link } from "gatsby"

export const LinkOrAnchor = ({to, external, children}) => (
  !external && (to && to.indexOf("http") !== 0) ?
    <Link to={to}>{children}</Link> :
    <a href={to} target={external ? external : "_blank"} rel="noopener noreferrer">{children}</a>
)

const LinkedButton = ({title, to, external, className}) => (
  <LinkOrAnchor to={to} external={external}>
    <button className={`button is-info ${className}`}><strong>{title}</strong></button>
  </LinkOrAnchor>
)

export default LinkedButton
