import React from "react"

import Layout from "src/components/layout"
import Seo from "src/components/seo"
import Header from "src/components/header"
import Footer from "src/components/footer"
import Caption from "src/components/scene/caption"
import { LinkOrAnchor } from "src/components/LinkedButton"
import LetsStart from "src/components/start"
import Wrap from "src/components/wrap"
import Spacer from "src/components/spacer"
import Styles from "./index.module.scss"

import IconTagLocationImg from "src/images/icon_tag_location@2x-min.png"
import IconTagUserImg from "src/images/icon_tag_user@2x-min.png"
import IconTagKadaiImg from "src/images/icon_tag_kadai@2x-min.png"
import ImageIndexComingsoonImg from "src/images/image_index_comingsoon@2x-min.png"
import FoodhallImg from "src/images/image_foodhall_hero@2x-min.png"
import OtodokemonImg from "src/images/otodokemon_main.png"

const IconTag = ({ icon, title }) => (
  <span className="tag">
    <div className="is-flex" style={{ alignItems: "center" }}>
      <span className="icon is-small mr-1"><img src={icon} alt="" /></span>
      <strong>{title}</strong>
    </div>
  </span>
)

const List = ({ children }) => (
  <div className="has-background-gray" style={{ background: "#dbe8ea" }}>
    <section className="section">
      <div className="container">
        {children}
      </div>
    </section>
  </div>
)

const ListItem = ({ url, image, title, location, user, kadai, solution, description }) => (
  <LinkOrAnchor to={url}>
    <div className={`box is-shadowless ${Styles.hoverBorderColor}`} style={{ borderWidth: "5px", borderStyle: "solid" }}>
      <div className="columns">
        <div className="column is-two-fifths">
          <figure><img src={image} alt="" /></figure>
        </div>
        <div className="column">
          <div className="title is-4 is-size-5-mobile"><Wrap>{title}</Wrap></div>
          <div>
            <IconTag icon={IconTagLocationImg} title={location} /> ×
            <IconTag icon={IconTagUserImg} title={user} /> ×
            <IconTag icon={IconTagKadaiImg} title={kadai} />
          </div>
          <div className="mt-2"><span className="tag is-primary"><strong>{solution}</strong></span></div>
          <p className="mt-3 is-size-6 is-size-7-mobile">{description}</p>
        </div>
      </div>
    </div>
  </LinkOrAnchor>
)

const ComingSoon = () => (
  <div className="section" style={{ background: "#dbe8ea" }}>
    <div className="container">
      <figure className="image"><img src={ImageIndexComingsoonImg} alt="" style={{ maxWidth: "393px", margin: "auto" }} /></figure>
    </div>
  </div>
)

const IndexPage = () => (
  <Layout>
    <Seo />
    <Header />
    <main>
      <Caption />
      <List>
        <ListItem
          url="/otodokemon"
          image={OtodokemonImg}
          title={<><span>構内の運搬</span><span>における</span><span>DXソリューション！</span></>}
          location="構内・オフィス"
          user="作業者"
          kadai="運搬作業が大変"
          solution="おトドけもん"
          description="構内で人が何度もモノを運ぶのは大変です。また、運搬記録のデジタル化も面倒ですよね。100サイズ荷物の運搬能力があるロボットにナタデココ拡張キットを搭載したおトドけもんなら、繰り返し人流が発生する運搬作業をスマートにデジタル化することができます。"
        />
        <Spacer isHalf />
        <ListItem
          url="/casestudy/scene/foodhall"
          image={FoodhallImg}
          title={<><span>フードホールで</span><span>席に座ったままらくらく注文</span></>}
          location="フードホール"
          user="来場者（グループ）"
          kadai="注文が大変"
          solution="モバイルオーダー"
          description="フードホールでは、席を確保しても、注文するためお店に行く必要があります。いろんなお店を回ったり並んだり、時間もかかって大変です。ナタデココを活用すれば、席にいながらお店のメニューをみてそのまま注文することができます。"
        />
      </List>
      <ComingSoon />
      <div style={{ background: "#dbe8ea" }}>
        <LetsStart />
      </div>
    </main>
    <Footer />
  </Layout>
)

export default IndexPage
