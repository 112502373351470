import React from "react"
import styles from "./header.module.scss"
import background from "src/images/pattern_light.svg"

// ページのヘッダー(タイトル)セクション
const Header = ({ children }) => (
  <section className="hero" style={{ backgroundImage: `url(${background})` }}>
    <div className={`hero-body ${styles.gradient}`}>
      <div className="container">
        <div className="content">
          {children}
        </div>
      </div>
    </div>
  </section>
)

export default Header