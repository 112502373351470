import React from "react"
import Wrap from "src/components/wrap"
import Button from "src/components/LinkedButton"

import TitlePrImg from "src/images/title_pr@2x-min.png"
import ConsolePickup1Img from "src/images/console_pickup_1@2x.png"

const Start = () => (
    <div className="section">
      <div className="container">
        <div className="box is-shadowless">
          <div className="columns">
            <div className="column has-text-centered">
              <figure className="image mb-5"><img src={TitlePrImg} alt="" style={{ maxWidth: "348px", margin: "auto" }}/></figure>
              <Wrap>
                <span>まずは管理コンソールにログイン！</span><br />
                <span>あとはメールアドレスの確認だけで</span><span>始められます。</span>
              </Wrap>
              <div className="is-hidden-mobile mt-5">
                <Button to="https://console.natade-coco.com/" title="管理コンソールへログイン" />
              </div>
            </div>
            <div className="column">
              <figure className="image"><img src={ConsolePickup1Img} alt="" style={{ maxWidth: "380px", margin: "auto" }}/></figure>
            </div>
          </div>
          <div className="is-hidden-tablet has-text-centered">
            <Button to="https://console.natade-coco.com/" title="管理コンソールへログイン" />
          </div>
        </div>
      </div>
    </div>
)

export default Start
