import React from "react"
import Figure from "src/components/figure"
import styles from "./image.module.scss"

// ヒーローの画像
const Image = ({ src, alt }) => (
  <div className="columns is-centered">
    <div className="column is-half">
      <Figure src={src} alt={alt} className={styles.image} />
    </div>
  </div>
)

export default Image
